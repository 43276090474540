import http from '@/utils/http'
import { ROLES, User } from '@/helpers/users/types'
import { Commit, Dispatch } from 'vuex'

export interface State {
	user: User
}

export default {
	namespaced: true,
	state: {
		user: {} as User,
	},
	getters: {
		login: (state: State): string => state.user.login,
		isAdmin: (state: State): boolean => state.user.roles?.includes(ROLES.ROLE_ADMIN),
		isUser: (state: State): boolean => state.user.roles?.includes(ROLES.ROLE_USER),
	},
	mutations: {
		setUserData(state: State, user: User): void {
			state.user = user
		},
	},
	actions: {
		async fetchUserData(): Promise<User> {
			return (await http.get('/me')).data
		},
		async setInitials({
			commit,
			dispatch,
		}: {
			dispatch: Dispatch
			commit: Commit
		}): Promise<void> {
			commit('setUserData', await dispatch('fetchUserData'))
		},
	},
}
