import { PageBoxCoordinatesResponse } from './types'
import { AxiosInstance } from 'axios'

type PagesApi = {
	fetchPageBoxesCoordinates: (pageId: number) => Promise<Array<PageBoxCoordinatesResponse>>
}

export default (http: AxiosInstance): PagesApi => ({
	async fetchPageBoxesCoordinates(pageId: number): Promise<Array<PageBoxCoordinatesResponse>> {
		return (await http.get(`page/${pageId}/attributes/rectangles`)).data
	},
})
