import { AxiosInstance } from 'axios'
import { fetchUsersResponse } from './types'
import { ROLES, User } from '@/helpers/users/types'

type UsersApi = {
	fetchUsers: (params: {
		createdOnFrom?: string
		createdOnTo?: string
		login?: string
		page: number
		size: number
	}) => Promise<fetchUsersResponse>
	createUser: (payload: { login: string; password: string }) => Promise<User>
	editUser: (userId: number, payload: { login: string; password: string }) => Promise<User>
	deleteUser: (userId: number) => Promise<void>
	restoreUser: (userId: number) => Promise<void>
}

export default (http: AxiosInstance): UsersApi => ({
	async fetchUsers(params: {
		createdOnFrom?: string
		createdOnTo?: string
		login?: string
		page: number
		size: number
	}): Promise<fetchUsersResponse> {
		return (await http.get('persons', { params })).data
	},
	async createUser({ login, password }: { login: string; password: string }): Promise<User> {
		return (await http.post('persons', { login, password, roles: [ROLES.ROLE_USER] })).data
	},
	async editUser(
		userId: number,
		{ login, password }: { login: string; password: string }
	): Promise<User> {
		return (await http.put(`persons/${userId}`, { login, password, roles: [ROLES.ROLE_USER] }))
			.data
	},
	deleteUser(userId: number): Promise<void> {
		return http.delete(`persons/${userId}`)
	},
	restoreUser(userId: number): Promise<void> {
		return http.post(`persons/${userId}/activate`)
	},
})
