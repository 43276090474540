import http from '@/utils/http'
import { State, Token, Options, Subscriber } from './types'
import { Commit, Dispatch } from 'vuex'

const httpInstance = http
const options: Readonly<Options> = {
	tokenKeyLS: 'user/token', // под каким ключом сохранять токен в localStorage
	authPath: '/sign/in', // путь к токену, может быть объектом (например, { refresh: 'sign/refresh', login: 'sign/in' })
	authPathRefresh: '/sign/refresh', // путь к токену, может быть объектом (например, { refresh: 'sign/refresh', login: 'sign/in' })
}

function getFullApiTokenPath(refresh = false): string {
	const { authPath, authPathRefresh } = options

	return `${process.env.VUE_APP_URL_API}${refresh ? authPathRefresh : authPath}`
}

function isGetTokenRequest(error: { response: { config: { url: string } } }) {
	const { url } = error.response.config

	return (
		url.indexOf(getFullApiTokenPath()) !== -1 || url.indexOf(getFullApiTokenPath(true)) !== -1
	)
}

function parseQuery(queryString: string) {
	if (!queryString) return {}

	const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&')

	return pairs.reduce((query, pair) => {
		const splittedPair: Array<string> = pair.split('=')
		query[decodeURIComponent(splittedPair[0])] = decodeURIComponent(splittedPair[1] || '')
		return query
	}, {} as Record<string, any>)
}

export default {
	namespaced: true,
	state(): State {
		return {
			token: {} as Token,
			isAuthorizing: false,
			isAuthorized: false,
		}
	},
	mutations: {
		SET_TOKEN(state: State, token: Token): void {
			state.token = token
			localStorage.setItem(options.tokenKeyLS, JSON.stringify(token))
			httpInstance.defaults.headers.common.Authorization = state.token.accessToken
				? `Bearer ${state.token.accessToken}`
				: ''
		},
		DROP_TOKEN(state: State): void {
			state.token = {} as Token
			localStorage.removeItem(options.tokenKeyLS)
			delete httpInstance.defaults.headers.common.Authorization
		},
		SET_AUTHORIZED(state: State, status: boolean): void {
			state.isAuthorized = status
		},
		SET_AUTHORIZING(state: State, status: boolean): void {
			state.isAuthorizing = status
		},
	},
	actions: {
		init({
			state,
			dispatch,
			commit,
		}: {
			state: State
			dispatch: Dispatch
			commit: Commit
		}): void {
			httpInstance.defaults.withCredentials = true

			let isAlreadyFetchingAccessToken = false
			let subscribers: Array<Subscriber> = []

			const addSubscriber = (cb: Subscriber) => {
				subscribers.push(cb)
			}

			const onTokensFetched = (token: string) => {
				subscribers = subscribers.filter(callback => callback(token))
			}

			httpInstance.interceptors.response.use(
				response => response,
				async error => {
					if (
						error.response &&
						error.response.status === 401 &&
						!isGetTokenRequest(error)
					) {
						error.disableUserNotice = true

						try {
							const originalRequest = error.config

							const retryOriginalRequest = new Promise(resolve => {
								addSubscriber(token => {
									originalRequest.headers.Authorization = `Bearer ${token}`
									resolve(httpInstance.request(originalRequest))
								})
							})

							if (!isAlreadyFetchingAccessToken) {
								isAlreadyFetchingAccessToken = true
								await dispatch('setToken', {
									refresh: true,
								})
								isAlreadyFetchingAccessToken = false
								onTokensFetched(state.token.accessToken)
							}

							return retryOriginalRequest
						} catch (err) {
							isAlreadyFetchingAccessToken = false
							dispatch('logout')
						}
					}

					throw error
				}
			)

			const { accessToken, refreshToken } = parseQuery(window?.location.search)

			if (accessToken && refreshToken) {
				commit('SET_TOKEN', {
					accessToken,
					refreshToken,
				})
				commit('SET_AUTHORIZED', true)
			} else dispatch('setToken')
		},
		dropToDefaults({ commit }: { commit: Commit }): void {
			commit('DROP_TOKEN')
		},
		async getToken(
			{ state }: { state: State },
			{
				refresh = false,
				login,
				password,
			}: { refresh: boolean; login?: string; password?: string }
		): Promise<Token | null> {
			const authParamsExist = !!login && !!password

			if (!authParamsExist && !refresh) {
				try {
					return JSON.parse(localStorage.getItem(options.tokenKeyLS) as string)
				} catch (error) {
					return null
				}
			}

			if (refresh && !state.token.refreshToken) throw Error

			let credentials = {}

			if (refresh) {
				credentials = {
					...credentials,
					refreshToken: state.token.refreshToken,
				}
			} else if (authParamsExist) {
				credentials = { ...credentials, login, password }
			} else {
				throw String('Не все параметры переданы')
			}

			const reqOptions = {
				withCredentials: true,
				baseURL: '',
			}

			return (await httpInstance.post(getFullApiTokenPath(refresh), credentials, reqOptions))
				.data
		},
		/**
		 * @description Запрашивает и кладёт в store токен.
		 *
		 * @param {Object} [payload] - параметры
		 * @param {string} [payload.username] - имя пользователя (email/login/username)
		 * @param {string} [payload.password] - пароль
		 * @param {boolean} [payload.refresh] - обновить токен
		 */
		async setToken(
			{ commit, dispatch }: { commit: Commit; dispatch: Dispatch },
			payload = { refresh: false }
		): Promise<void> {
			commit('SET_AUTHORIZING', true)

			const { refresh, ...params } = payload

			const token = await dispatch('getToken', { ...params, refresh })

			if (token) {
				commit('SET_TOKEN', token)
				commit('SET_AUTHORIZED', true)
			} else {
				commit('DROP_TOKEN')
			}

			commit('SET_AUTHORIZING', false)
		},
		login(
			{ dispatch }: { dispatch: Dispatch },
			payload: { login: string; password: string }
		): Promise<void> {
			return dispatch('setToken', payload)
		},
		async logout({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }): Promise<void> {
			commit('SET_AUTHORIZED', false)

			dispatch('dropToDefaults')

			/* Object.keys(this._actions) // eslint-disable-line
				.filter(action => /dropToDefaults/.test(action))
				.forEach(action => dispatch(action, null, { root: true })) */
		},
	},
}
