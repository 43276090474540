import { RouteRecordRaw } from 'vue-router'
import { defineAsyncComponent } from 'vue'
import { ROLES } from '@/helpers/users/types'

export const routes: Array<RouteRecordRaw> = [
	{
		name: 'DocumentPackages',
		path: '/',
		meta: {
			title: 'Пакеты документов',
		},
		component: () => import('@/pages/documents/document-packages/DocumentPackages.vue'),
	},
	{
		name: 'CreateDocumentsPackage',
		path: '/create-documents-package',
		meta: {
			title: 'Создание пакета',
		},
		component: defineAsyncComponent(
			() => import('@/pages/documents/CreateDocumentsPackage.vue')
		),
	},
	{
		name: 'CreatingPackageStages',
		path: '/create-documents-package/:id',
		meta: {
			title: 'Пакет с документами',
		},
		component: defineAsyncComponent(
			() => import('@/pages/documents/creating-package-stages/CreatingPackageStages.vue')
		),
	},
	{
		name: 'Users',
		path: '/users',
		meta: {
			authorities: [ROLES.ROLE_ADMIN],
			title: 'Пользователи',
		},
		component: defineAsyncComponent(() => import('@/pages/users/Users.vue')),
	},
	{
		name: 'Settings',
		path: '/settings',
		meta: {
			authorities: [ROLES.ROLE_ADMIN],
			title: 'Настройки приложения',
		},
		component: defineAsyncComponent(() => import('@/pages/settings/Settings.vue')),
	},
	{
		path: '/:catchAll(.*)',
		name: 'NotFound',
		component: defineAsyncComponent(() => import('@/pages/404.vue')),
	},
]
