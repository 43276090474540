import { InjectionKey, inject } from 'vue'

export default function injectStrict<T>(key: InjectionKey<T> | string, fallback?: T): T {
	const resolved = inject(key, fallback)

	if (!resolved) {
		throw new Error(`Could not resolve ${typeof key === 'string' ? key : key.description}`)
	}

	return resolved
}
