<script setup>
import { defineProps, ref, computed, watch } from 'vue'

const props = defineProps({
	width: {
		type: [Number, String],
		default: 24,
	},
	height: {
		type: [Number, String],
		default: 24,
	},
	name: {
		type: String,
		required: false,
		default: 'empty-icon',
	},
	address: {
		type: String,
		required: false,
		default: 'https://static.itigris.ru/icons',
	},
})

const svgContent = ref('')

const boxSize = computed(() => {
	if (props.name) {
		const size = Number(props.name.substr(props.name.lastIndexOf('-') + 1))
		return Number.isNaN(size) ? 24 : size
	}

	return 24
})

watch(
	() => props.name,
	() => {
		setSvgContent()
	},
	{ immediate: true }
)

async function setSvgContent() {
	try {
		if (props.name) {
			svgContent.value = await loadIcon(`${props.name}.xml`)
		} else {
			setEmptyIcon()
		}
	} catch (error) {
		if (error.code === 'MODULE_NOT_FOUND') {
			setEmptyIcon()
		} else {
			console.dir(error) // eslint-disable-line no-console
		}
	}
}

async function setEmptyIcon() {
	svgContent.value = await loadIcon(`empty-icon.xml`)
}

function loadIcon(icon) {
	const url = `${props.address}/${icon}`
	return new Promise((resolve, reject) => {
		const xhr = new XMLHttpRequest()
		xhr.open('GET', url)
		xhr.send()
		xhr.onload = function () {
			if (xhr.status >= 400) {
				return reject(
					new Error({
						status: xhr.status,
						statusText: xhr.statusText,
					})
				)
			}
			return resolve(xhr.response)
		}
		xhr.onerror = function (err) {
			return reject(err)
		}
	})
}
</script>

<template>
	<!-- eslint-disable vue/no-v-html -->
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="width"
		:height="height"
		:viewBox="`0 0 ${boxSize} ${boxSize}`"
		class="it-icon"
		v-html="svgContent"
	/>
</template>
