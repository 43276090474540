import dayjsOriginal from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { App } from 'vue'
import { dayjsInjectionKey } from '@/utils/provide-inject/injection-keys'

dayjsOriginal.extend(utc)
dayjsOriginal.extend(timezone)

export const dayjs = dayjsOriginal

export type DayjsOriginal = typeof dayjs

export default {
	install(app: App): void {
		app.config.globalProperties.$dayjs = dayjsOriginal
		app.provide(dayjsInjectionKey, dayjsOriginal)
	},
}
