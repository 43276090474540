import { Api } from '@/plugins/api'
import { DayjsOriginal } from '@/plugins/common/dayjs'
import { NotifyUserAboutError } from '@/plugins/common/notifyUserAboutError'
import { InjectionKey } from 'vue'

export const dayjsInjectionKey: InjectionKey<DayjsOriginal> = Symbol('dayjs')
export const notifyErrorInjectionKey: InjectionKey<NotifyUserAboutError> =
	Symbol('notifyUserAboutError')
export const apiInjectionKey: InjectionKey<Api> = Symbol('api')
export const packageIdKey: InjectionKey<number> = Symbol('packageId')
