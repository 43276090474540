import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import ItIcon from '@/components/common/ItIcon.vue'
import locale from 'element-plus/lib/locale/lang/ru'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './plugins/api'
import notifyUserAboutError from './plugins/common/notifyUserAboutError'
import dayjs from './plugins/common/dayjs'
import 'element-plus/dist/index.css'
import './assets/styles/index.scss'

store.dispatch('auth/init')

const app = createApp(App)
	.use(store)
	.use(router)
	.use(api)
	.use(ElementPlus, { locale })
	.use(notifyUserAboutError)
	.use(dayjs)

app.component('ItIcon', ItIcon)

app.mount('#app')
