import { AxiosInstance } from 'axios'

type SettingsApi = {
	fetchSettings: () => Promise<Record<string, string>>
	editSettings: (settings: Record<string, string>) => Promise<void>
}

export default (http: AxiosInstance): SettingsApi => ({
	async fetchSettings(): Promise<Record<string, string>> {
		return (await http.get('settings')).data
	},
	async editSettings(settings: Record<string, string>): Promise<void> {
		return (await http.post('settings', settings)).data
	},
})
