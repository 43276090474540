import { notifyErrorInjectionKey } from '@/utils/provide-inject/injection-keys'
import { ElNotification } from 'element-plus'
import { App } from 'vue'

type ErrObj = Record<string, { msg: string }>

type ErrorResponseDataObj = {
	text?: () => Promise<string>
	status?: string
	errors?: Array<{ field: string; message: string }> | ErrObj
	error?: string
	message?: string
}

type ErrorResponseData = ErrorResponseDataObj | Array<{ title: string }>

interface ErrorResponse {
	data: ErrorResponseData
	status?: string
}
export interface ErrorTypeObj {
	disableUserNotice?: boolean
	response?: string | ErrorResponse
	field?: string
	message?: string
}

export type ErrorType = string | ErrorTypeObj

function errorIsString(error: ErrorType): error is string {
	return typeof error === 'string'
}

function errorIsObject(error: ErrorType): error is ErrorTypeObj {
	return typeof error === 'object'
}

async function notifyUserAboutError<T extends ErrorType>(error: T): Promise<void> {
	const title = 'Ошибка'

	if (errorIsString(error)) {
		ElNotification.error({ title, message: error })
	} else if (errorIsObject(error)) {
		if (error.disableUserNotice) return

		if (typeof error.response === 'undefined' || error.message) {
			const message = [error.field, error.message].filter(t => Boolean(t)).join(' ')
			ElNotification.error({ title, message })
		} else {
			const { response } = error

			if (typeof response === 'string') {
				ElNotification.error({ title, message: response })
			} else {
				if (Array.isArray(response.data) && response.data[0] && response.data[0].title) {
					ElNotification.error({ title, message: response.data[0].title })
				} else {
					if ('text' in response.data && typeof response.data.text === 'function') {
						try {
							const text = await response.data.text()
							response.data = JSON.parse(text)
						} catch (err) {
							console.error(err)
						}
					}

					if ('errors' in response.data && response.data.errors !== undefined) {
						let message = ''

						if (Array.isArray(response.data.errors)) {
							response.data.errors.forEach((e: Record<string, unknown>) => {
								const errorStr = [e.field, e.message]
									.filter(t => Boolean(t))
									.join(' ')
								message += `${errorStr}<br>`
							})
						} else {
							Object.keys(response.data.errors).forEach((e: string) => {
								const errors = (response.data as ErrorResponseDataObj)
									.errors as ErrObj
								message += `${e} ${errors[e].msg}<br>`
							})
						}

						ElNotification.error({
							title,
							dangerouslyUseHTMLString: true,
							message,
						})
					} else {
						let message
						if ('error' in response.data) {
							message = response.data.error
						} else if ('message' in response.data) {
							message = response.data.message
						} else {
							message = 'Contact technical support'
						}

						ElNotification.error({
							title: `${title} ${
								'status' in response.data ? response.data.status : response.status
							}`,
							message,
						})

						if (process.env.VUE_APP_MODE === 'production') {
							console.dir(error)
						}
					}
				}
			}
		}
	}
}

export type NotifyUserAboutError = typeof notifyUserAboutError

export default {
	install(app: App): void {
		app.config.globalProperties.$notifyUserAboutError = notifyUserAboutError
		app.provide(notifyErrorInjectionKey, notifyUserAboutError)
	},
}
