import http from '@/utils/http'
import users from './modules/users'
import documents from './modules/documents'
import settings from './modules/settings'
import pages from './modules/pages'
import { App } from 'vue'
import { apiInjectionKey } from '@/utils/provide-inject/injection-keys'

export const api = {
	users: users(http),
	documents: documents(http),
	settings: settings(http),
	pages: pages(http),
}

export type Api = typeof api

export default {
	install(app: App): void {
		app.config.globalProperties.$api = api
		app.provide(apiInjectionKey, api)
	},
}
