import { documentStatuses } from './constants'

export type DocumentStatuses = (typeof documentStatuses)[keyof typeof documentStatuses]

export type DocumentsPackage = {
	id: number
	name: string
	createdAt: string
	updatedAt: string
	deletedAt: string | null
	status: DocumentStatuses
	type: string
}

export type DocumentsPackages = Array<DocumentsPackage>

export interface DocumentsPackagesType {
	id: number
	name: string
}

export type DocumentsPackagesTypes = Array<DocumentsPackagesType>

export interface UploadedFile {
	id: number
	originalName: string
}

export interface UploadedFileType extends UploadedFile {
	contentType: string
}

export interface UploadedFileUrl extends UploadedFile {
	uploadUrl: string
	name: string
}

export interface FileInProgressRaw {
	id?: number
	name: string
	label: string | null
	status: DocumentStatuses
	url: string
	pages: Array<{
		id?: number
		name: string
		label: string
		status: DocumentStatuses
		failMessage: string | null
		imgUrl: string | null
		generated: boolean
	}>
}

export interface XmlFileResult {
	name: string
	url: string
}

export interface ResponsePackageDocument {
	result: XmlFileResult
	files: FileInProgressRaw[]
}

export enum FileStatuses {
	FILE_IN_PROCESS = 'fileInProcess',
	FILE_NOT_PROCESSED = 'fileNotProcessed',
	FILE_FAILED = 'fileFailed',
	FILE_DONE = 'fileDone',
}

export type FileStatus = (typeof FileStatuses)[keyof typeof FileStatuses]

export interface DocumentInProgressPrepared {
	name: string
	label: string | null
	statusLabel: string
	status: FileStatus
	failMessage?: string | null
	url?: string
	children?: Array<{
		name: string
		label: string
		statusLabel: string
		status: FileStatus
		failMessage: string | null
	}>
}

export interface PageAttribute {
	id: number
	code: string
	text: string
	confidence: number
}
export interface DocumentVerify {
	name: string
	id: number
	pages: Array<{
		id: number
		url: string
		imgPreprocessingUrl: string
		sortOrder: number
		text: string
		attributes: Array<PageAttribute>
		items: Array<Record<string, string>>
	}>
}

export type DocumentTableItem = Record<string, string>

export type Page = {
	id: number
	label: string
	attributes: Array<PageAttribute>
	items: Array<DocumentTableItem>
	url: string
}

export enum CMR {
	Sender = 'Sender',
	Consignee = 'Consignee',
	VehicleNo = 'VehicleNo',
	Carrier = 'Carrier',
	Number = 'Number',
	Date = 'Date',
	LoadingCountry = 'LoadingCountry',
	SpecialAgreements = 'SpecialAgreements',
	SenderInstuctions = 'SenderInstuctions',
	VehicleNoOptional = 'VehicleNoOptional',
	CarrierOptional = 'CarrierOptional',
	DateShipping = 'DateShipping',
	SenderCity = 'SenderCity',
	SenderCtreet = 'SenderCtreet',
	SenderPost = 'SenderPost',
	SenderCountry = 'SenderCountry',
	SenderCountryCode = 'SenderCountryCode',
	ConsigneeCity = 'ConsigneeCity',
	ConsigneeCtreet = 'ConsigneeCtreet',
	ConsigneePost = 'ConsigneePost',
	ConsigneeCountry = 'ConsigneeCountry',
	ConsigneeCountryCode = 'ConsigneeCountryCode',
	CarrierCity = 'CarrierCity',
	CarrierCtreet = 'CarrierCtreet',
	CarrierPost = 'CarrierPost',
	CarrierCountry = 'CarrierCountry',
	CarrierCountryCode = 'CarrierCountryCode',
	LoadingCountryCode = 'LoadingCountryCode',
	SenderInstuctionsCity = 'SenderInstuctionsCity',
	DestinationCountry = 'DestinationCountry',
}

export enum INVOICE {
	Number = 'Number',
	Date = 'Date',
	Currency = 'Currency',
	Sender = 'Sender',
	Consignee = 'Consignee',
	TotalAmount = 'TotalAmount',
	TotalTax = 'TotalTax',
	productCode = 'productCode',
	productCodeTn = 'productCodeTn',
	description = 'description',
	quantity = 'quantity',
	grossWeight = 'grossWeight',
	netWeight = 'netWeight',
	taxRate = 'taxRate',
	tax = 'tax',
	unitPrice = 'unitPrice',
	amountLine = 'amountLine',
	unitType = 'unitType',
}

export enum PASSPORT_RF {
	SERIES = 'SERIES',
	NUMBER = 'NUMBER',
	AUTHORITY_NAME = 'AUTHORITY_NAME',
	ISSUE_DATE = 'ISSUE_DATE',
	AUTHORITY_CODE = 'AUTHORITY_CODE',
	LAST_NAME = 'LAST_NAME',
	FIRST_NAME = 'FIRST_NAME',
	MIDDLE_NAME = 'MIDDLE_NAME',
	GENDER = 'GENDER',
	BIRTHPLACE = 'BIRTHPLACE',
	BIRTHDAY = 'BIRTHDAY',
}

export enum PASSPORT_INT {
	Name = 'Name',
	Surname = 'Surname',
	Nationality = 'Nationality',
	Number = 'Number',
	DateOfBirth = 'DateOfBirth',
	DateOfIssue = 'DateOfIssue',
	DateOfExpiry = 'DateOfExpiry',
	PlaceOfBirth = 'PlaceOfBirth',
	PNumber = 'PNumber',
	PSeries = 'PSeries',
}
export enum CTC {
	Number = 'Number',
	Reg_number_trailer = 'Reg_number_trailer',
	Reg_number_car = 'Reg_number_car',
	VIN = 'VIN',
	Weight = 'Weight',
	Mass = 'Mass',
	Year = 'Year',
	Engine_power = 'Engine_power',
}
export enum ADMISSION {
	Number = 'Number',
	Issue_date = 'Issue_date',
	Valid_date = 'Valid_date',
	Reg_number_trailer = 'Reg_number_trailer',
	Reg_number_car = 'Reg_number_car',
}
