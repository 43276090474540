export const enum Statuses {
	ACTIVE = 'Активный',
	DELETED = 'Удален',
}

export const enum ROLES {
	ROLE_USER = 'ROLE_USER',
	ROLE_ADMIN = 'ROLE_ADMIN',
}

export type User = {
	id: number
	login: string
	createdAt: string
	updatedAt: string
	deletedAt: string | null
	roles: Array<ROLES>
}
