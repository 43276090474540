import { AxiosInstance } from 'axios'
import { documentStatuses } from '@/helpers/documents/constants'
import {
	FetchDocumentsResponse,
	CreateDocumentRequest,
	PreuploadResponse,
	SaveRequest,
	FetchDocumentsRequest,
} from './types'
import { UploadUserFile } from 'element-plus'
import {
	DocumentStatuses,
	DocumentVerify,
	DocumentsPackagesTypes,
	UploadedFileType,
	ResponsePackageDocument,
} from '@/helpers/documents/types'

type DocumentsApi = {
	fetchDocuments: (params: FetchDocumentsRequest) => Promise<FetchDocumentsResponse>
	fetchDocumentsStatuses: () => Promise<
		Array<(typeof documentStatuses)[keyof typeof documentStatuses]>
	>
	fetchDocumentsPackagesTypes: () => Promise<DocumentsPackagesTypes>
	preupload: (payload: { files: Array<UploadedFileType> }) => Promise<PreuploadResponse>
	uploadFile: (urlUpload: string, file: UploadUserFile) => Promise<void>
	createDocument: (id: number, payload: CreateDocumentRequest) => Promise<void>
	fetchPackageStatus: (id: number) => Promise<DocumentStatuses>
	fetchPackageData: (id: number, status?: DocumentStatuses) => Promise<ResponsePackageDocument>
	fetchDocumentsVerify: (id: number) => Promise<Array<DocumentVerify>>
	savePackageData: (id: number, payload: SaveRequest) => Promise<void>
	verifyPackage: (id: number) => Promise<void>
	removePackage: (id: number, full: boolean) => Promise<void>
}

export default (http: AxiosInstance): DocumentsApi => ({
	async fetchDocuments(params: FetchDocumentsRequest): Promise<FetchDocumentsResponse> {
		return (
			await http.get('documents', {
				params,
			})
		).data
	},
	// TODO скорее всего эндпоинт не будет использован
	async fetchDocumentsStatuses(): Promise<
		Array<(typeof documentStatuses)[keyof typeof documentStatuses]>
	> {
		// return (await http.get('documents/statuses')).data

		return [
			documentStatuses.CREATED,
			documentStatuses.PAGINATION,
			documentStatuses.PAGINATED,
			documentStatuses.PAGINATION_FAILED,
			documentStatuses.CLASSIFICATION,
			documentStatuses.CLASSIFIED,
			documentStatuses.CLASSIFICATION_FAILED,
			documentStatuses.CLASSIFICATION_ALL_OTHERS,
			documentStatuses.RECOGNITION,
			documentStatuses.RECOGNIZED,
			documentStatuses.RECOGNITION_FAILED,
			documentStatuses.VERIFIED,
			documentStatuses.REPORT_GENERATION,
			documentStatuses.REPORT_GENERATED,
			documentStatuses.REPORT_GENERATION_FAILED,
		]
	},
	async fetchDocumentsPackagesTypes(): Promise<DocumentsPackagesTypes> {
		return (await http.get('documents/types')).data.content
	},
	async preupload(payload: { files: Array<UploadedFileType> }): Promise<PreuploadResponse> {
		return (await http.post('documents/pre-upload', payload)).data
	},
	async uploadFile(urlUpload: string, file: UploadUserFile): Promise<void> {
		if (!file.raw) throw new Error('There is no file info')

		await http.put(urlUpload, new Blob([file.raw], { type: file.raw.type }), {
			headers: {
				'Content-Type': file.raw.type,
				Authorization: false,
			},
		})
	},
	createDocument(id: number, payload: CreateDocumentRequest): Promise<void> {
		return http.post(`documents/${id}`, payload)
	},
	async fetchPackageStatus(id: number): Promise<DocumentStatuses> {
		return (await http.get(`documents/${id}/status`)).data.status
	},
	async fetchPackageData(id: number, status?: DocumentStatuses): Promise<ResponsePackageDocument> {
		return (await http.get(`documents/${id}`, { params: { status } })).data
	},
	async fetchDocumentsVerify(id: number): Promise<Array<DocumentVerify>> {
		return (await http.get(`documents/${id}/verify`)).data.documents

		/* return [
			{
				id: 1,
				name: 'passport',
				pages: [
					{
						id: 1,
						url: '',
						sortOrder: 4,
						text: 'passport Страница 1',
						attributes: [
							{
								id: 1,
								code: 'passport Стр 1 Серия',
								text: '0606',
								confidence: 100,
							},
							{
								id: 2,
								code: 'Номер',
								text: '304001',
								confidence: 100,
							},
							{
								id: 3,
								code: 'Фамилия',
								text: 'Халабудина',
								confidence: 100,
							},
							{
								id: 4,
								code: 'Имя',
								text: 'Юлия',
								confidence: 100,
							},
							{
								id: 5,
								code: 'Отчество',
								text: 'Алексеевна',
								confidence: 100,
							},
						],
						items: [
							{
								param1: 'val1',
								param2: 'val2',
								param3: 'val3',
								param4: 'val4',
							},
							{
								param1: 'val1val1',
								param2: 'val2val2',
								param3: 'val3val3',
								param4: 'val4val4',
							},
							{
								param1: 'val1val1val1',
								param2: 'val2val2val2',
								param3: 'val3val3val3',
								param4: 'val4val4val4',
							},
						],
					},
					{
						id: 2,
						url: '',
						sortOrder: 4,
						text: 'passport Страница 2',
						attributes: [
							{
								id: 1,
								code: 'passport Стр 2 Серия',
								text: '0606',
								confidence: 100,
							},
							{
								id: 2,
								code: 'Номер',
								text: '304001',
								confidence: 100,
							},
							{
								id: 3,
								code: 'Фамилия',
								text: 'Халабудина',
								confidence: 100,
							},
							{
								id: 4,
								code: 'Имя',
								text: 'Юлия',
								confidence: 100,
							},
							{
								id: 5,
								code: 'Отчество',
								text: 'Алексеевна',
								confidence: 100,
							},
						],
						items: [],
					},
				],
			},
			{
				id: 2,
				name: 'invoice',
				pages: [
					{
						id: 3,
						url: '',
						sortOrder: 4,
						text: 'invoice Страница 1',
						attributes: [
							{
								id: 1,
								code: 'invoice Стр 1 Серия',
								text: '0606',
								confidence: 100,
							},
							{
								id: 2,
								code: 'Номер',
								text: '304001',
								confidence: 100,
							},
							{
								id: 3,
								code: 'Фамилия',
								text: 'Халабудина',
								confidence: 100,
							},
							{
								id: 4,
								code: 'Имя',
								text: 'Юлия',
								confidence: 100,
							},
							{
								id: 5,
								code: 'Отчество',
								text: 'Алексеевна',
								confidence: 100,
							},
						],
						items: [],
					},
					{
						id: 4,
						url: '',
						sortOrder: 4,
						text: 'invoice Страница 2',
						attributes: [
							{
								id: 1,
								code: 'invoice Стр 2 Серия',
								text: '0606',
								confidence: 100,
							},
							{
								id: 2,
								code: 'Номер',
								text: '304001',
								confidence: 100,
							},
							{
								id: 3,
								code: 'Фамилия',
								text: 'Халабудина',
								confidence: 100,
							},
							{
								id: 4,
								code: 'Имя',
								text: 'Юлия',
								confidence: 100,
							},
							{
								id: 5,
								code: 'Отчество',
								text: 'Алексеевна',
								confidence: 100,
							},
						],
						items: [],
					},
				],
			},
		] */
	},
	async savePackageData(id: number, payload: SaveRequest): Promise<void> {
		return (await http.put(`documents/${id}`, payload)).data
	},
	async verifyPackage(id: number): Promise<void> {
		return (await http.post(`documents/${id}/verify`)).data
	},
	async removePackage(id: number, full: boolean): Promise<void> {
		return (await http.delete(`documents/${id}`, { params: { full } })).data
	},
})
