import axios from 'axios'

const httpInstance = axios.create({
	baseURL: process.env.VUE_APP_URL_API,
})

httpInstance.defaults.paramsSerializer = function (params) {
	const cleanedParams = Object.keys(params).reduce((acc, key) => {
		if (params[key] === null || typeof params[key] === 'undefined') return acc
		acc[key] = params[key]
		return acc
	}, {} as Record<string, string>)
	return new URLSearchParams(cleanedParams).toString()
}

export default httpInstance
